.menu-navbar {
  position: fixed;
  height: 60px;
  width: 100%;
  top: 0;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  transition: top 0.2s ease-in-out, visibility 0.2s ease-in-out;
  padding-top: 20px;
  margin-bottom: 10px;
  z-index: 1;
}

.menu-left {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 5px;
  height: calc(100% - 10px);
}

.menu-right {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  height: calc(100% - 20px);
  #width: 475px;
  width: 350px;
  margin-right: 15px;
  transition: width 0.25s linear;
  #background: rgba(255,255,255,0.65);
  background: radial-gradient(rgba(169,169,169,0.4), rgba(169,169,169,0));

  border-radius: 5px;
}

.menu-socials {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  align-items: center;
  height: 100%;
}
.menu-social {
  fill: #000;
  display: flex;
  flex-direction: row;
  height: 23px;
  width: 18px;
  transition: width 0.2s linear, height 0.2s linear, color 0.2s linear, margin-top 0.2s linear;
}
.menu-social:hover{
  width: 20px;
}

.menu-options {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  #width: 70%;
  font-family: open-sans-regular;
  align-items: center;
}
.menu-option {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #000;
  margin-left: 1px;
  margin-right: 1px;
  color: white;
  transition: font-size 0.2s linear, color 0.2s linear, margin-top 0.2s linear;
}
.menu-option:hover{
  color: white;
  font-size: 107.5%;
}

.menu-option-separator {
  color: white;

}

@media screen and (max-width: 800px){
  .menu-left {
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-logo {
    display: flex;
    height: 20px;
  }
  .menu-text-logo{
    font-size: 20px;
  }
  .menu-right {
    width: 210px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }
  .menu-socials{
    align-items: flex-end;
    height: 18px;
    width: 75px;
    justify-content: space-between;
  }
  .menu-social{
    height: 18px;
    width: 18px;
  }
  .menu-social:hover{
    height: 20px;
    width: 20px;
  }
  .menu-options{
    width: 100%;
    height: 20px;
    justify-content: space-between;
  }
  .menu-option {
    height: 20px;
    font-size: 16px;
  }
}

@media screen and (min-width: 800px) {
  .menu-text-logo{
    margin-top: 8px;
  }
  .menu-socials {
    width: 25%;
  }
  .menu-options {
    #width: 70%;
    width: 90%;
  }
}
