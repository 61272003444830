.button-image {
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(1.35);
    transition: transform 0.75s, filter 0.75s;
}
.button-image:hover {
     transform: scale(1.40);
     filter: grayscale(1);
}

.button-text-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: white;
}

.button-description{
  display: flex;
  text-align: center;
  font-size: 16px;
  color: black;
  margin-bottom: 20px;
  z-index: 2;
  transition: font-size 0.5s ease, color 0.5s ease;
}

.button-text-wrapper, .button-image{
  position: absolute;
}

.button-image:hover ~ .button-text-wrapper {
  color: black;
}
.button-image:hover ~ .button-text-wrapper > .button-description{
  color: black;
}