body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

div:hover, div:visited, div:link, div:active {
  text-decoration: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

@font-face {
  font-family: kiro-regular;
  src: url(fonts/Kiro-Regular.ttf);
}
@font-face {
  font-family: open-sans-regular;
  src: url(fonts/Open-Sans-Regular.ttf);
}

.container{
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  position: relative;
  flex-direction: column;
}

.background {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  #-webkit-filter: grayscale(1) invert(1);
  #filter: grayscale(1) invert(1);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-image {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 35%;
  transform: scale(1);
  transition: transform 0.75s, filter 0.75s;
  #animation: fade 1s ;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.text{
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
}

.text-logo {
  height: 100%;
  text-align: center;
  font-family: kiro-regular;
  font-size: 100px;
  text-decoration: none;
  color: white;
}

.sub-logo {
  text-align: center;
  font-family: kiro-regular;
  color: white;
  font-size: 50px;
}

.sub-text {
  display: flex;
  align-items: center;
  justify-content: center;
  #font-family: "Century Gothic";
  font-family: kiro-regular;
  font-weight: bold;
  #text-transform: uppercase;
  color: black;
  height: 45px;
  font-size: 20px;
}

.bottom{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  #background: linear-gradient(to top, rgba(112,145,90,0.6), rgba(112,145,90,0));
  background: linear-gradient(to top, rgba(169,169,169,0.4), rgba(169,169,169,0));
  padding: 0px 10px 10px 10px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 50px;
  font-family: kiro-regular;
  text-decoration: none;
  color: white;
}

.description {
  display: flex;
  text-align: justify-all;
  color: white;
  width: 100vw;
  align-items: center;
  font-family: open-sans-regular;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 1px;
  height: 25vh;
  background-color: white;
}

.button-wrapper {
  height: 100%;
  overflow:hidden;
  position: relative;
}

@media screen and (max-width: 350px){
  .button-wrapper{
    width: 100vw;
    min-height: 125px;
  }
  .logo {
    margin-top: 60px;
    margin-bottom: 15px;
  }
  .text-logo {
    font-size: 55px;
  }
  .sub-logo {
    font-size: 35px;
  }
  .sub-text{
    font-size: 16px;
  }
  .title {
    max-width: calc(100vw / 1.1);
  }
  .description {
    max-width: calc(100vw / 1.1);
    margin-top: 30px;
  }
  .bottom{
    flex-direction: column;
    align-items: center;
  }
  .text {
    margin-bottom: 15px;
  }
  .main {
    min-height: 300px;
  }
}

@media screen and (min-width: 350px) and (max-width: 800px){
  .button-wrapper{
    width: 100vw;
    min-height: 200px;
  }
  .logo {
    margin-top: 60px;
    margin-bottom: 15px;
  }
  .text-logo {
    font-size: 60px;
  }
  .sub-logo {
    font-size: 35px;
  }
  .title {
    #font-size: 75px;
    max-width: calc(100vw / 1.1);
  }
  .description {
    max-width: calc(100vw / 1.1);
    margin-top: 50px;
  }
  .bottom{
    flex-direction: column;
    align-items: center;
  }
  .text {
    margin-bottom: 15px;
  }
  .main {
    min-height: 325px;
  }
}

@media screen and (min-width: 800px) {
  .main {
    #min-height: max(400px, calc(75vh - 0.75px - 45px));
  }
  .button-wrapper {
    width: calc(100vw / 3.0001 - 0.75px);
  }
  .text {
    min-height: max(400px, calc(75vh - 0.75px - 45px));
  }
  .logo {
    margin-top: 15vh;
    margin-bottom: 15px;
  }
  .buttons {
    min-height: 165px;
  }
  .title {
    margin-left: 15px;
    margin-right: 10px;
  }
  .description {
    margin-right: 15px;
  }
}